<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.new_m", {
                m: $t("message.meeting"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">
                  <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
                    <el-form-item
                    prop="name"
                    :label="$t('message.name')"
                    >
                      <el-input
                          :placeholder="$t('message.name')"
                          v-model="form.name"
                          size="medium"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                    <el-form-item
                    prop="date"
                    :label="$t('message.date')"
                    >
                      <el-date-picker
                        v-model="form.date"
                        type="datetimerange"
                        align="right"
                        size="medium"
                        class="w-100"
                        :start-placeholder="$t('message.from')"
                        :end-placeholder="$t('message.to')"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :default-time="['10:00:00', '18:00:00']">
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                    <!-- end-col -->
                </el-row>

                <el-row :gutter="20">
                  <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                    <el-form-item
                    prop="exhibition_type_id"
                    :label="$t('message.type')"
                    >
                      <type
                        :main_type="main_type"
                        :placeholder="columns.type.title"
                        v-model="form.exhibition_type_id"
                        :id="form.exhibition_type_id"
                      ></type>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                    <el-form-item
                    prop="country_id"
                    :label="$t('message.country_id')"
                    >
                      <country
                        :placeholder="columns.country_id.title"
                        v-model="form.country_id"
                        :id="form.country_id"
                      ></country>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                    <el-form-item
                    prop="city_id"
                    :label="$t('message.city_id')"
                    >
                      <city
                        :placeholder="columns.city_id.title"
                        v-model="form.city_id"
                        :id="form.city_id"
                        :country_id="form.country_id"
                      ></city>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                    <el-form-item
                    prop="status"
                    :label="$t('message.status')"
                    >
                      <select-status
                        :placeholder="columns.status.title"
                        v-model="form.status"
                        :id="form.status"
                      ></select-status>
                    </el-form-item>
                  </el-col>
                </el-row>

				<!-- Notification -->
				<el-row :gutter="20">
					<el-divider content-position="left">{{ $t('message.notifications_nn') }}</el-divider>
					<el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-form-item class="mt-1 mb-0 pb-0">
						<el-switch
							v-model="form.notify"
							active-color="#13ce66"
							inactive-color="#ff4949">
						</el-switch>
						</el-form-item>
					</el-col>
					<el-col v-if="form.notify" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
					<el-form-item  :label="$t('message.notificationTypes')" prop="notification_type_id">
						<select-notification-types 
							v-model="form.notification_type_id"
							:size="'large'"
							:id="form.notification_type_id">
						</select-notification-types>
					</el-form-item>
					</el-col>
					<el-col v-if="form.notify" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
						<el-form-item class="mt-0 pt-0" :label="'Напомнить за ' + (form.notification_time ? form.notification_time : '') + ' минут раньше'">
						<el-select  v-model="form.notification_time" placeholder="минут" class="w-100" clearable>
							<el-option
							v-for="item in minutes"
							:key="item.value"
							:label="item.label + ' минут раньше'"
							:value="item.value">
							</el-option>
						</el-select>
						</el-form-item>
					</el-col>
					<!-- end-col -->
                </el-row>
				<!-- Notification -->

                <el-row :gutter="20">
                  <el-col :span="24">
                     <el-form-item
                    prop="comment"
                    :label="$t('message.comment')"
                    >
                      <el-input
                        type="textarea"
                        :autosize="{ minRows: 2 }"
                        :placeholder="$t('message.comment')"
                        v-model="form.comment">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-tabs type="border-card" style="overflow: auto;">
            <el-tab-pane style="min-height: 600px;" :label="$t('message.users')" >

              <div class="gc-card__body px-3 py-4">

              <div class="text-descr mb-0">
                <span class="text-descr__label d-block">{{ $t("message.responsible") }}</span>
                <div
                  class="
                    responsible-block
                    d-flex
                    align-center
                    font-medium
                    p-2
                    rounded-sm
                  "
                >
                  <div
                    class="gc-small-block rounded-circle hidden mr-3"
                    style="border: 1px solid gray"
                  >
                    <img
                      :src="
                        authUser
                          ? (authUser.avatar
                            ? authUser.avatar
                            : '/img/default-user-icon.png')
                          : '/img/default-user-icon.png'
                      "
                      alt="Default Avatar"
                      class="d-block"
                    />
                  </div>
                  <div class="f-fill">
                    <span class="text-descr__text f-fill">{{
                      authUser.name
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="text-descr mb-0">
                <span class="text-descr__label d-block">{{ $t('message.participants') }}</span>
                <div
                  class="
                    responsible-block
                    d-flex
                    align-center
                    font-medium
                    p-2
                    rounded-sm
                    pointer
                    p-relative
                  "
                >
                  <div
                    class="gc-small-block rounded-circle hidden mr-3"
                    @click="getSelectedUsers"
                  >
                    <img
                      src="/img/default-user-icon.png"
                      alt="Default Avatar"
                      class="d-block"
                    />
                  </div>
                  <div
                    class="f-fill d-flex align-center"
                    @click="getSelectedUsers"
                  >
                    <span class="text-descr__text f-fill"
                      >{{ $t('message.add_participant') }}</span
                    >
                    <i class="el-icon-plus ml-auto"></i>
                  </div>

                  <div
                    class="
                      responsible-block-dropdown
                      p-absolute
                      w-100
                      rounded
                      p-3
                    "
                  >
                    <div class="responsible-block-search">
                      <div class="app-form__group">
                        <div class="responsible-block-input">
                          <input
                            type="text"
                            class="gc-country-input"
                            :placeholder="$t('message.search')"
                            @input="findResponsibleUsers"
                            ref="responsibleUsersInput"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="responsible-users-dropdown rounded p-3 mt-2"
                      ref="usersDropdown"
                    >
                      <perfect-scrollbar style="height: 200px">
                        <div
                          v-for="user in usersList"
                          :key="user.id"
                          @click="selectUsers(user)"
                          class="
                            users-dropdown-items
                            d-flex
                            p-2
                            rounded
                            transition
                          "
                        >
                          <div class="user-items-left mr-2">
                            <div
                              class="
                                user-item-avatar
                                w-100
                                h-100
                                rounded-pill
                                hidden
                              "
                            >
                              <img
                                :src="
                                  user
                                    ? (user.avatar
                                      ? user.avatar
                                      : '/img/default-user-icon.png')
                                    : '/img/default-user-icon.png'
                                "
                                alt
                              />
                            </div>
                          </div>
                          <div class="user-items-right">
                            <h4 class="my-1">
                              {{ user.name }}
                            </h4>
                            <p class="m-0">
                              {{ user.phone }}
                            </p>
                          </div>
                        </div>
                      </perfect-scrollbar>

                      <div class="custom__button mt-3">
                        <el-button
                          @click="setUsers"
                          type="primary"
                          icon="el-icon-user"
                          size="medium"
                          > {{$t('message.add')}} </el-button
                        >
                      </div>
                    </div>

                    <div
                      v-for="user in selectedUsersList"
                      :key="user.id"
                      class="users-dropdown-items d-flex p-2 rounded transition"
                    >
                      <div class="user-items-left mr-2">
                        <div
                          style="border: 1px solid gray"
                          class="user-item-avatar w-100 h-100 rounded-pill hidden"
                        >
                          <img
                            :src="
                              user
                                ? (user.avatar
                                  ? user.avatar
                                  : '/img/default-user-icon.png')
                                : '/img/default-user-icon.png'
                            "
                            alt
                          />
                        </div>
                      </div>
                      <div class="user-items-right">
                        <h4 class="my-1">
                          {{ user.name }}
                        </h4>
                        <p class="m-0">
                          {{ user.phone }}
                        </p>
                      </div>
                      <div
                        class="f-fill d-flex align-center"
                        @click="removeFromSelectedUsers(user)"
                      >
                        <i class="el-icon-delete icon__delete ml-auto"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              </div>

            </el-tab-pane>
            <el-tab-pane :label="$t('message.products')">
              <el-row :gutter="20">
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <products
                      ref="products"
                      v-model="form.products"
                      :product_ids="form.products"
                      :size="'medium'"
                      :placeholder="$t('message.products')"
                    >
                  </products>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="mt-3">
                <el-col  :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-for="product in updateProductList" :key="product.id" class="mt-2">
                  <el-button-group >
                    <el-button size="mini"  type="info"> {{product.name}} </el-button>
                    <el-button @click="removeProductFromList(product.id)" class="ml-1" size="mini" type="danger" icon="el-icon-delete"></el-button>
                  </el-button-group>
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane :label="$t('message.expenses')">
              <el-button @click="drawerCreateCostTransaction = true" class="m-3" type="primary"  size="medium">
                {{$t('message.payments_for_expenses')}}
              </el-button>
              <template>
                <el-table
                  :data="form.costTransactions"
                  border
                  style="width: 100%">
                  <el-table-column
                    prop="id"
                    :label="$t('message.n')"
                    width="50">
                  </el-table-column>
                  <el-table-column
                    prop="costType"
                    :label="$t('message.costType')">
                    <template slot-scope="scope">
                      {{ scope.row.costType ?  scope.row.costType.name : ''}}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="bankAccount"
                    :label="$t('message.bankAccount')">
                    <template slot-scope="scope">
                      {{ scope.row.bankAccount ?  scope.row.bankAccount.name : ''}}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="money_amount"
                    :label="$t('message.money_amount')">
                    <template slot-scope="scope">
                      {{ scope.row.money_amount | formatNumber(1) }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="currency_rate"
                    :label="$t('message.rate')">
                  </el-table-column>
                  <el-table-column
                    prop="currency"
                    :label="$t('message.currency')">
                    <template slot-scope="scope">
                      {{ scope.row.bankAccount ?  (scope.row.bankAccount.currency ? scope.row.bankAccount.currency.code : '')  : ''}}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="paymentType"
                    :label="$t('message.paymentType')">
                    <template slot-scope="scope">
                      {{ scope.row.paymentType ?  scope.row.paymentType.name : ''}}
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('message.settings')">
                    <template slot-scope="scope">
                      <el-button @click="updateCost(scope.row, scope.$index)" type="primary" icon="el-icon-edit" size="medium"></el-button>
                      <el-button @click="deleteCost(scope.row, scope.$index)" type="danger" icon="el-icon-delete" size="medium"></el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-tab-pane>
          </el-tabs>

        </div>
      </div>
      <!-- end app-modal__body -->
      <div class="app-modal app-modal__full modal-color-bg">
        <el-drawer
            :with-header="false"
            :visible.sync="drawerCreateCostTransaction"
            ref="drawerCreateCostTransaction"
            size="70%"
            :append-to-body="true"
            @opened="drawerOpened('drawerCreateCostTransactionChild')"
            @closed="drawerClosed('drawerCreateCostTransactionChild')"
            >
            <div>
                <CreateCostTransaction
                  ref="drawerCreateCostTransactionChild"
                  :unsubmittable="true"
                  @newCost="newCost"
                  drawer="drawerCreateCostTransaction">
                </CreateCostTransaction>
            </div>
        </el-drawer>

        <el-drawer
            :with-header="false"
            :visible.sync="drawerUpdateCostTransaction"
            size="70%"
            :append-to-body="true"
            ref="drawerUpdateCostTransaction"
            @opened="drawerOpened('drawerUpdateCostTransactionChild')"
            @closed="drawerClosed('drawerUpdateCostTransactionChild')"
        >
            <UpdateCostTransaction
                :selectedItem="selectedCostTransaction"
                :unsubmittable="true"
                @newCostUpdate="newCostUpdate"
                ref="drawerUpdateCostTransactionChild"
                drawer="drawerUpdateCostTransaction"
            ></UpdateCostTransaction>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import list from "@/utils/mixins/child_list";
import exhibition from "@/utils/mixins/views/exhibition";
import selectStatus from "@/components/inventory/select-exhibition-status"

export default {
  mixins: [form, drawer, list, exhibition],
  props: {
    main_type:{
      default: 2
    }
  },
  components: {
    selectStatus
  },
  data() {
    return{
    }
  },
  created() {},
  computed: {
      ...mapGetters({
          model: "exhibitions/model",
          authUser: "auth/user",
      }),
      updateProductList(){
        return this.products.filter(el => this.form.products.includes(el.id));
      }
  },

  methods: {
      ...mapActions({
        save: "exhibitions/store",
      }),

      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.form.begin_date = this.form.date[0];
                  this.form.end_date = this.form.date[1];
                  this.form.main_type = this.main_type;

                  this.form.costTransactions = this.form.costTransactions.map(el => {
                    return {
                        id: el.id,
                        cost_type_id: el.cost_type_id,
                        payment_type_id: el.payment_type_id,
                        bank_account_id: el.bank_account_id,
                        money_amount: el.money_amount,
                        currency_rate: el.currency_rate,
                        date: el.date,
                        comment: el.comment,
                        created_at: el.created_at,
                        updated_at: el.updated_at,
                      }
                  })
                  this.form.user_id = this.authUser.id;
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },
  },
};
</script>
<style>
.el-form--label-top .el-form-item__label {
    margin: 0px !important;
    padding: 0px !important;
}
</style>

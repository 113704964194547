<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> {{ $t("message.meetings") }} </div>
              <div class="block-sarche">
                <div class="header__search">
                  
                   <crm-input
                        :size="'small'"
                        :class="mode ? 'input__day' : 'input__night'"
                        :className="'w100'"
                        v-model="filterForm.search"
                        :icon="'el-icon-search'"
                    ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">

             <crm-create-and-column-settings
                  :permission="$options.name"
                  :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                  @c-create="drawerCreate = true"
                  :columns="columns"
                  @c-change="updateColumn"
              >
              </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table 
          class="table-my-code table-bordered"
          :class="mode ? 'table__myday' : 'table__mynight'"
          v-loading="loadingData"
        >
          <thead>
            <tr>
                <th class="w50p" v-if="columns.id.show">
                    {{ columns.id.title }}
                </th>

                <th v-if="columns.status.show">
                    {{ columns.status.title }}
                </th>

                <th v-if="columns.name.show">
                    {{ columns.name.title }}
                </th>

                <th v-if="columns.user_id.show">
                    {{ columns.user_id.title }}
                </th>

                <th v-if="columns.type.show">
                    {{ columns.type.title }}
                </th>

                <th v-if="columns.clients.show">
                    {{ columns.clients.title }}
                </th>

                <th v-if="columns.deals.show">
                    {{ columns.deals.title }}
                </th>

                <th v-if="columns.costTransactions.show">
                    {{ columns.costTransactions.title }}
                </th>

                <th v-if="columns.money_amount.show">
                    {{ columns.money_amount.title }}
                </th>

                <th v-if="columns.country_id.show">
                    {{ columns.country_id.title }}
                </th>

                <th v-if="columns.city_id.show">
                    {{ columns.city_id.title }}
                </th>

                <th v-if="columns.begin_date.show">
                    {{ columns.begin_date.title }}
                </th>

                <th v-if="columns.end_date.show">
                    {{ columns.end_date.title }}
                </th>

                <th v-if="columns.comment.show">
                    {{ columns.comment.title }}
                </th>
                
                <th v-if="columns.created_at.show">
                    {{ columns.created_at.title }}
                </th>

                <th v-if="columns.updated_at.show">
                    {{ columns.updated_at.title }}
                </th>

                <th v-if="columns.settings.show">
                    {{ columns.settings.title }}
                </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                  <el-input
                      clearable
                      size="mini"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.id"
                      :placeholder="columns.id.title"
                      class="id_input"
                  ></el-input>
              </th>

              <th v-if="columns.city_id.show">
                  <select-status
                      :placeholder="columns.status.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.status"
                      :id="filterForm.status"
                  ></select-status>
              </th>

              <th v-if="columns.name.show">
                  <crm-input
                      :placeholder="columns.name.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.name"
                  ></crm-input>
              </th>

              <th v-if="columns.user_id.show">
                  <crm-input
                      :placeholder="columns.user_id.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.user_id"
                  ></crm-input>
              </th>

              <th v-if="columns.type.show">
                  <type
                      :main_type="TYPE_MEETING"
                      :placeholder="columns.type.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.exhibition_type_id"
                      :id="filterForm.exhibition_type_id"
                  ></type>
              </th>
              
              <th v-if="columns.clients.show">
                  <crm-input
                      disabled
                      :placeholder="columns.clients.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.clients"
                  ></crm-input>
              </th>
              
              <th v-if="columns.deals.show">
                  <crm-input
                      disabled
                      :placeholder="columns.deals.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.deals"
                  ></crm-input>
              </th>
              
              <th v-if="columns.costTransactions.show">
                  <crm-input
                      disabled
                      :placeholder="columns.costTransactions.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.costTransactions"
                  ></crm-input>
              </th>
              
              <th v-if="columns.money_amount.show">
                  <crm-input
                      disabled
                      :placeholder="columns.money_amount.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.money_amount"
                  ></crm-input>
              </th>
              
              <th v-if="columns.country_id.show">
                  <country
                      :placeholder="columns.country_id.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.country_id"
                      :id="filterForm.country_id"
                  ></country>
              </th>
              
              <th v-if="columns.city_id.show">
                  <city
                      :placeholder="columns.city_id.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.city_id"
                      :id="filterForm.city_id"
                      :country_id="filterForm.country_id"
                  ></city>
              </th>
              
              <th v-if="columns.begin_date.show">
                  <crm-input
                      :placeholder="columns.begin_date.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.begin_date"
                  ></crm-input>
              </th>
              
              <th v-if="columns.end_date.show">
                  <crm-input
                      :placeholder="columns.end_date.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.end_date"
                  ></crm-input>
              </th>

              <th v-if="columns.comment.show">
                  <crm-input
                      disabled
                      :placeholder="columns.comment.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.comment"
                  ></crm-input>
              </th>

              <th v-if="columns.created_at.show">
                  <crm-date-picker
                      :placeholder="columns.created_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.created_at"
                  ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                  <crm-date-picker
                      :placeholder="columns.updated_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.updated_at"
                  ></crm-date-picker>
              </th>

              <th
                  class="settinW"
                  v-if="columns.settings.show"
              ></th>
          </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="exhibition in list" :key="exhibition.id" class="cursor-pointer">

                <td v-if="columns.id.show">
                    {{ exhibition.id }}
                </td>

                <td v-if="columns.status.show">
                    <div 
                        v-if="exhibition.status"
                        class="mm-exhibition-status" 
                        :style="exhibition.status == 1 ? 'background-color: rgb(4, 182, 4)' : 'background-color: rgb(250, 57, 67)'">
                            {{ exhibition.statusName ? exhibition.statusName.name : '' }}
                    </div>
                </td>

                <td v-if="columns.name.show">
                    <router-link
                        class="nav-item-top-link action"
                        :to="{ name: 'exhibitionHome', params: { exhibition_id: exhibition.id, main_type: TYPE_MEETING } }"
                    >
                    {{ exhibition.name }}
                    </router-link>
                </td>

                <td v-if="columns.user_id.show">
                    {{ exhibition.user ? exhibition.user.name : '' }}
                </td>

                <td v-if="columns.type.show">
                    {{ exhibition.type ? exhibition.type.name : '' }}
                </td>

                <td v-if="columns.clients.show">
                    {{ exhibition.clients }}
                </td>

                <td v-if="columns.deals.show">
                    {{ exhibition.deals }}
                </td>

                <td v-if="columns.costTransactions.show">
                    {{ exhibition.costTotal | formatMoney(2) }}
                </td>

                <td v-if="columns.money_amount.show">
                    {{ exhibition.money_amount | formatMoney(2) }}
                </td>

                <td v-if="columns.country_id.show">
                    {{ exhibition.country ? exhibition.country.name : '' }}
                </td>

                <td v-if="columns.city_id.show">
                    {{ exhibition.city ? exhibition.city.name : '' }}
                </td>

                <td v-if="columns.begin_date.show">
                    {{ exhibition.begin_date }}
                </td>

                <td v-if="columns.end_date.show">
                    {{ exhibition.end_date }}
                </td>

                <td v-if="columns.comment.show">
                    {{ exhibition.comment }}
                </td>
               
                <td v-if="columns.created_at.show">
                    {{ exhibition.created_at }}
                </td>

                <td v-if="columns.updated_at.show">
                    {{ exhibition.updated_at }}
                </td>

                <td v-if="columns.settings.show" class="settings-td">
                     
                     <crm-settings
                        :name="$options.name"
                        :model="exhibition"
                        :actions="actions"
                        :permissionShow="'exhibitions.update'"
                        :permissionDestroy="'exhibitions.delete'"
                        @edit="edit"
                        @delete="destroy"
                    ></crm-settings>
                </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
          <!-- <Pagination /> -->
        </div>
      </div>

        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
               :with-header="false"  
               :visible.sync="drawerCreate" 
               ref="drawerCreate"
                class="my-bgd body_scroll_80"
               size="80%"
               @opened="drawerOpened('drawerCreateChild')"
               @closed="drawerClosed('drawerCreateChild')"
               >
                <div class="mt-5">
                    <crm-create 
                        :main_type="TYPE_MEETING"
                        ref="drawerCreateChild" 
                        drawer="drawerCreate"> 
                    </crm-create>
                </div>
            </el-drawer>

            <el-drawer
                :with-header="false"
                :visible.sync="drawerUpdate"
                size="80%"
                class="my-bgd body_scroll_80"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
               <div class="mt-5">
                    <crm-update
                        :main_type="TYPE_MEETING"
                        :selectedItemFirst="selectedItem"
                        ref="drawerUpdateChild"
                        drawer="drawerUpdate"
                    ></crm-update>
                </div>
            </el-drawer> 
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import Type from "@/components/inventory/select-exhibition-type.vue"
import Country from "@/components/inventory/select-country.vue"
import City from "@/components/inventory/select-city.vue"
import selectStatus from "@/components/inventory/select-exhibition-status.vue"

import { mapGetters, mapActions } from "vuex";

export default {
    name: "exhibitions",
    mixins: [list],
    components: {
        Pagination,
        CrmCreate,
        CrmUpdate,
        Type,
        Country,
        City,
        selectStatus
    },

    data() {
        return {
            TYPE_MEETING: 2
        };
    },

    computed: {
        ...mapGetters({
            list: "exhibitions/list",
            columns: "exhibitions/columns",
            pagination: "exhibitions/pagination",            
            filter: "exhibitions/filter",
            sort: "exhibitions/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "exhibitions/index",
            setPagination: "exhibitions/setPagination",
            updateSort: "exhibitions/updateSort",
            updateFilter: "exhibitions/updateFilter",
            updateColumn: "exhibitions/updateColumn",
            updatePagination: "exhibitions/updatePagination",
            show: "exhibitions/show",
            empty: "exhibitions/empty",
            delete: "exhibitions/destroy",
            refreshData: "exhibitions/refreshData",
        }),

        fetchData() {
            const query = {...this.filter, ...this.pagination, ...this.sort, main_type: this.TYPE_MEETING};

            if (!this.loadingData) {
                this.loadingData = true;
                this.updateList(query).then(res => {
                    this.loadingData = false
                }).catch(err => {
                    this.loadingData = false
                });
            }
        },
    
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('exhibitions/EMPTY_LIST');
        next()
    },
};
</script>
<style>
.mm-exhibition-status{
    color: #fff;
    padding: 5px;
    border-radius: 3px;
}
</style>
